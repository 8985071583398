//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'FreeStockBanner',
  props: {
    channelType: String,
  },
  mixins: [channelTypesMixin],
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
  },
  data() {
    return {
      faAngleRight,
    };
  },
  computed: {
    freeLink() {
      return _.get(this.getChannelTypeById(this.channelType), 'freeLink');
    },
    freeHeading() {
      // eslint-disable-next-line newline-per-chained-call
      return _.upperFirst(this.$term('mediaType', this.channelType).wbr().seo().free().plural().html());
    },
  },
};
